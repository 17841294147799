import React from 'react';

class Footer extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <footer>
        <div className="datex-footer-wrapper ">
          <div className="datex-footer-container datex-container clearfix">
            <div className="datex-footer-column datex-item-pdlr datex-column-20">
              <div id="text-1" className="widget widget_text datex-widget">
                <div className="textwidget">
                  <p>
                    <img
                      className="alignnone size-full wp-image-5803"
                      src="upload/logo.png"
                      alt=""
                      width="138"
                    />
                    <br />{' '}
                    <span
                      className="datex-space-shortcode"
                      style={{ marginTop: '-27px' }}
                    ></span>
                    <br /> DATEX Worldwide private limited is established to
                    supply customers and trading partners with a fully
                    customized logistics solutions services.
                  </p>
                </div>
              </div>
            </div>
            <div className="datex-footer-column datex-item-pdlr datex-column-20">
              <div id="text-5" className="widget widget_text datex-widget">
                <h3 className="datex-widget-title">Location</h3>
                <span className="clear"></span>
                <div className="textwidget">
                  <p>
                    No. 5, First Floor, Swastik House,
                    <br />
                    Near Income Tax Railway Crossing,
                    <br />
                    Stadium Road,
                    <br />
                    Navrangpura,
                    <br />
                    Ahmedabad – 380009
                    <br />
                    Gujarat
                    <br />
                    India
                  </p>
                </div>
              </div>
            </div>
            <div className="datex-footer-column datex-item-pdlr datex-column-20">
              <div id="text-5" className="widget widget_text datex-widget">
                <h3 className="datex-widget-title">Contact Info</h3>
                <span className="clear"></span>
                <div className="textwidget">
                  <p>
                    <span style={{ color: '#fff' }}>+91-84014-73108</span>
                    <br />{' '}
                    <span style={{ color: '#187542' }}>
                      <a href="mailto:info@datexcargo.com">
                        info@datexcargo.com
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="datex-footer-column datex-item-pdlr datex-column-30">
              <div
                id="datex-custom-menu-widget-2"
                className="widget widget_datex-custom-menu-widget datex-widget"
              >
                <h3 className="datex-widget-title">Useful Links</h3>
                <span className="clear"></span>
                <div className="menu-useful-links-container">
                  <ul
                    id="menu-useful-links"
                    className="datex-custom-menu-widget datex-menu-style-half"
                  >
                    <li className="menu-item">
                      <a href="our-services.html">Our Services</a>
                    </li>
                    <li className="menu-item">
                      <a href="industry-solutions.html">Industry Solutions</a>
                    </li>
                    <li className="menu-item">
                      <a href="/">Our Partners</a>
                    </li>
                    <li className="menu-item">
                      <a href="get-a-quote.html">Get A Quote</a>
                    </li>
                    <li className="menu-item">
                      <a href="/">FAQ</a>
                    </li>
                    <li className="menu-item">
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="datex-copyright-wrapper">
          <div className="datex-copyright-container datex-container clearfix">
            <div className="datex-copyright-left datex-item-pdlr">
              Copyright@2021 by Datex worldwide private limited, All Right
              Reserved.
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
